import * as React from "react"
import { css } from "@emotion/react"
import { Link } from "gatsby"
const kebabCase = require("lodash.kebabcase")

export const TagsFrame = ({ title }) => {
  return (
    <React.Fragment>
      <section
        css={css`
          text-align: center;
          margin-bottom: 2rem;
          padding: 1rem;
          background-color: #fff;
        `}
      >
        <span
          css={css`
            font-size: var(--fontSize-3);
            font-weight: bold;
            color: var(--color-text-light);
          `}
        >
          #{title}
        </span>
      </section>
    </React.Fragment>
  )
}

export const TagsNavi = ({ tags }) => {
  return (
    <div>
      <ol
        css={css`
          list-style: none;
          li {
            display: inline-block;
          }
        `}
      >
        {tags.map((element, index) => {
          return (
            <li key={index}>
              <small
                css={css`
                  margin-right: 0.4rem;
                  padding: 0.3rem 0.2rem;
                  a {
                    font-weight: bold;
                    color: var(--color-tags);
                  }
                `}
              >
                <Link to={"/tags/" + kebabCase(element) + "/"} itemProp="url">
                  #{element}
                </Link>
              </small>
            </li>
          )
        })}
      </ol>
    </div>
  )
}
