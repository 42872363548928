import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { TagsFrame } from "../components/tags"
import { GoldenRatio } from "../components/utils/golden_ratio"
import { css } from "@emotion/react"
import {
  CssDesktop,
  CssNotMobile,
  CssTablet,
} from "../components/utils/responsive"
import {
  content_frame_desktop,
  content_frame_tablet,
  frame_common,
} from "../components/utils/css"
const kebabCase = require("lodash.kebabcase")

const Tags = ({ data, location }) => {
  const title = "ALL"
  const dict = {}
  data.tagsGroup.group.forEach(x => {
    dict[x.fieldValue] = x.totalCount
  })

  return (
    <Fragment>
      <Layout location={location}>
        <Seo title={`#${title}`} slug={"/tags/"} />
        <section
          css={css`
            ${frame_common};
            ${CssDesktop(content_frame_desktop)};
            ${CssTablet(content_frame_tablet)};
            div {
              background-color: #fff;
              padding: 1rem;
              ${CssNotMobile(css`
                display: flex;
              `)};
            }
            ul {
              padding-left: ${GoldenRatio(2)}rem;
              margin: auto;
              li {
                padding: ${GoldenRatio(-3)}rem;
                a,
                span {
                  font-weight: bold;
                }
                a {
                  color: var(--color-text-light);
                }
                span {
                  margin-left: ${GoldenRatio(-3)}rem;
                }
                :hover {
                  a {
                    background-color: var(--color-primary);
                    color: #fff;
                  }
                  span {
                    background-color: var(--color-primary);
                    color: #fff;
                  }
                }
              }
            }
          `}
        >
          <TagsFrame title={title} />
          <div>
            <ul>
              <TagItem name="プログラミング" dict={dict} />
              <ul>
                <TagItem name="WEBサイト作り" dict={dict} />
                <ul>
                  <TagItem name="WordPress" dict={dict} />
                  <TagItem name="GatsbyJS" dict={dict} />
                </ul>
              </ul>
              <TagItem name="ゲーム" dict={dict} />
              <ul>
                <TagItem name="GodsUnchained" dict={dict} />
              </ul>
            </ul>
            <ul>
              <TagItem name="アクアリウム" dict={dict} />
              <TagItem name="仮想通貨" dict={dict} />
              <TagItem name="パソコン" dict={dict} />
              <TagItem name="その他" dict={dict} />
            </ul>
          </div>
        </section>
      </Layout>
    </Fragment>
  )
}
export default Tags

const TagItem = ({ name, dict }) => {
  return (
    <li key={name}>
      <Link to={`/tags/${kebabCase(name)}/`}>
        #{name}
        <span>({dict[name]})</span>
      </Link>
    </li>
  )
}

export const tagsQuery = graphql`
  query {
    tagsGroup: allMdx {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
